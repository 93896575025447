<template>
  <div class="test-group-detail">
    <div class="back-button-container">
      <a
        @click="clearTestGroup()"
        class="backButton"
        id="back-btn"
        plain
        :class="{ active: false }"
      >
        <!-- <img
						class="flag"
						:src="require(`@/assets/images/flags/${language.code}.png`)"
					/> -->
        <p class="font-medium block notification-title">
          <feather-icon
            icon="ChevronLeftIcon"
            class="inline-block mr-2 back-button-icon"
            svgClasses="w-4 h-7"
          />
          <span class="back-button-span">
            {{
              lang.botMaker.testGroups.view.backToTestGroup[languageSelected]
            }}</span
          >
        </p>
      </a>
    </div>
    <div class="vx-row test-case-header" id="test-case-header">
      <vs-col vs-w="10">
        <vs-row vs-w="8" class="flex title">
          <h2>
            {{
              lang.botMaker.testGroups.table.groups.testSuite[languageSelected]
            }}
          </h2>
        </vs-row>
        <vs-row vs-w="8" class="flex title">
          <h1>
            {{ selectedTestGroup.name }}
          </h1>
        </vs-row>
      </vs-col>
      <vs-col vs-w="2" class="more-button-container">
        <div
          class="moreCaseButton more-button-icon"
          v-if="
            isAuthAux('konecta.test_groups.execute') ||
            isAuthAux('konecta.test_groups.edit')
          "
        >
          <feather-icon
            icon="MoreVerticalIcon"
            id="more-options-btn"
            class="inline-block mr-8 more-button-icon"
            @click="moreGroupOptions()"
            svgClasses="w-8 h-8"
          >
          </feather-icon>
          <KonectaDropdown
            :click-outside="outsideCase"
            v-if="groupOptionsPopup"
          >
            <div>
              <ul>
                <li
                  @click="openTestGroupEditor(selectedTestGroup)"
                  id="edit-group-btn"
                  v-if="isAuthAux('konecta.test_groups.edit')"
                >
                  <vs-row class="option-row">
                    <feather-icon
                      icon="EditIcon"
                      class="inline-block mr-2"
                      svgClasses="w-4 h-4"
                    />
                    <p>
                      {{ lang.botMaker.testGroups.view.edit[languageSelected] }}
                    </p>
                  </vs-row>
                </li>
                <li
                  @click="excecuteTestGroup(selectedTestGroup._id)"
                  id="execute-group-btn"
                  v-if="isAuthAux('konecta.test_groups.execute')"
                >
                  <vs-row class="option-row">
                    <feather-icon
                      icon="PlayCircleIcon"
                      class="inline-block mr-2"
                      svgClasses="w-4 h-4"
                    />
                    <p>
                      {{
                        lang.botMaker.testGroups.view.execute[languageSelected]
                      }}
                    </p>
                  </vs-row>
                </li>
              </ul>
            </div>
          </KonectaDropdown>
        </div>
      </vs-col>
    </div>
    <div class="vx-row test-group-table" id="test-group-table">
      <vs-col vs-w="12">
        <div class="pt-4 pb-base vs-con-loading__container">
          <vs-table
            :search="true"
            :key="listKey"
            :data="selectedTestGroup.testCases"
            :hoverFlat="true"
            @mouseover="() => undefined"
            @mouseup="() => undefined"
            :max-items="15"
            ref="table"
            :no-data-text="lang.general.noDataText[languageSelected]"
          >
            <!-- pagination
            @change-page="handleChangePage"
            :total="total"-->
            <template slot="header">
              <vs-button
                id="btn-create-case"
                class="float-right mb-3"
                @click="openAddTestCase()"
              >
                {{
                  lang.botMaker.testGroups.view.addTestCase[languageSelected]
                }}
              </vs-button>
            </template>
            <template slot="thead">
              <vs-th vs-w="1" key="testSuite">{{
                lang.botMaker.testGroups.table.cases.testCase[languageSelected]
              }}</vs-th>
              <vs-th key="testDescription">{{
                lang.botMaker.testGroups.view.description[languageSelected]
              }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr
                :data="indextr"
                :key="indextr"
                v-for="(tr, indextr) in data"
                class="bg-white"
                height="60px"
              >
                <vs-td :data="data[indextr].name">
                  {{ data[indextr].name }}
                </vs-td>
                <vs-td :data="data[indextr].description">
                  {{ data[indextr].description }}
                </vs-td>
                <vs-td>
                  <ul class="horizontal-nav">
                    <li
                      class="navbar-item cursor-pointer"
                      @click="selectTestCase(data[indextr]._id)"
                      v-if="isAuthAux('konecta.test_groups.list')"
                      :id="`detail-btn-${indextr}`"
                    >
                      <feather-icon
                        icon="ListIcon"
                        class="inline-block mr-2"
                        svgClasses="w-8 h-8"
                      />
                    </li>
                    <li
                      class="navbar-item cursor-pointer"
                      @click="openAddTestCase(data[indextr])"
                      v-if="isAuthAux('konecta.test_groups.edit')"
                      :id="`edit-btn-${indextr}`"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="inline-block mr-2"
                        svgClasses="w-8 h-8"
                      />
                    </li>
                    <li
                      class="navbar-item cursor-pointer"
                      @click="deleteCaseConfirmation(data[indextr])"
                      v-if="isAuthAux('konecta.test_groups.delete')"
                      :id="`delete-btn-${indextr}`"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="inline-block mr-2"
                        svgClasses="w-8 h-8"
                      />
                    </li>
                  </ul>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <!-- <vs-pagination
            class="mt-2 float-right"
						:value="currentPage"
            v-if="selectedTestGroup.testCases.length"
            :total="Math.ceil(selectedTestGroup.testCases.length / 15)"
						@change="handleChangePage"
          /> -->
        </div>
      </vs-col>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
// import NewFormGroup from '../add-bot/Components/NewFormGroup.vue'
import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  name: 'botTest',
  mixins: [staffManagementMixin],
  components: {
    // NewFormGrokkkup,
    KonectaDropdown: () => import('../../../../components/KonectaDropdown.vue'),
    VsTable: () => import('@/components/vx-table/vsTable'),
    VsTh: () => import('@/components/vx-table/vsTh'),
    VsTr: () => import('@/components/vx-table/vsTr'),
    VsTd: () => import('@/components/vx-table/vsTd')
    // EmptyTable: () => import('../../components/EmptyTable.vue'),
  },
  props: {
    openAddTest: Function,
    openAddTestCase: Function,
    excecuteTestGroup: Function,
    deleteCaseConfirmation: Function,
    listKey: Number
  },
  data() {
    return {
      activePopup: false,
      groupOptionsPopup: false,
      createTestCase: {
        name: '',
        description: ''
      },
      validations: {
        createTestCase: {
          nameError: false,
          descriptionError: false
        }
      }
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session']),
    ...mapGetters('botMaker', ['selectedTestGroup', 'botId', 'bot'])
  },
  methods: {
    ...mapActions('botMaker', [
      'SET_TEST_CASE',
      'GET_TEST_GROUPS',
      'UNSET_TEST_GROUP',
      'GET_BOT'
    ]),
    openAddTestCasePopup() {
      this.activePopup = true
    },
    moreGroupOptions() {
      this.groupOptionsPopup = true
    },
    outsideCase: function () {
      this.caseOptionsPopup = false
      this.groupOptionsPopup = false
    },
    openTestGroupEditor(testGroup) {
      this.caseOptionsPopup = false
      this.groupOptionsPopup = false
      this.openAddTest(testGroup)
    },
    cancel() {
      this.activePopup = false
    },
    clearTestGroup() {
      this.UNSET_TEST_GROUP()
    },
    async selectTestCase(testCaseId) {
      this.$vs.loading()
      await this.SET_TEST_CASE(testCaseId)
      this.$vs.loading.close()
    }
  },
  async mounted() {}
}
</script>

<style lang="scss">
.test-group-detail {
  #btn-create-case {
    margin-left: 0px !important;
    margin-right: auto;
  }
  #test-group {
    .title {
      margin-bottom: 20px;
    }
  }
  .back-button-container {
    display: inline-block;
  }
  .back-button-icon {
    text-align: top;
    vertical-align: middle;
    margin-right: 0px !important;
  }
  .back-button-span {
    vertical-align: text-bottom;
  }
  .backButton {
    display: flex;
    align-items: center;
    cursor: pointer;

    &.active,
    // &:hover {
    //   color: rgba(var(--vs-primary), 1);
    // }

    .flag {
      margin-right: 5px;
      margin-bottom: 2px;
      border-radius: 50%;
      width: 16px;
      height: 16px;
    }
  }
}
</style>

<style>
.vs-table--tbody {
  z-index: 1 !important;
  min-width: 0% !important;
}

.vs-table--tbody-table {
  min-width: 0% !important;
}
</style>

<style scoped>
.center {
  margin: auto;
  width: 100%;
}
</style>

<style lang="scss">
.test-group-detail {
  .more-button-icon {
    float: right;
    margin-left: auto;
    margin-right: 0px !important;
  }
  .more-button-container {
    display: flex;
    position: relative;
    align-items: center;
    .konecta-dropdown {
      margin-top: 79px;
      right: -93px;
    }
  }
  .test-case-header {
    margin: 30px 0px;
    margin-top: 0px;
    padding: 20px;
    background-color: #fafafa;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    .error-message {
      display: block;
      height: 19px;
      color: rgba(var(--vs-danger), 1);
      font-size: 0.65rem;
    }
    h2 {
      font-size: 14px;
    }
    .title {
      margin-bottom: 5px !important;
      h2 {
        color: grey;
      }
    }
    .btn-actions {
      display: flex;
      justify-content: flex-end;
    }

    .vs-pagination--buttons,
    .item-pagination {
      width: 30px;
      height: 30px;
    }
    .item-pagination {
      font-size: 0.8rem;
    }

    .con-vs-pagination {
      padding: 7px;
    }
    .horizontal-nav {
      display: flex;
      float: right;
      margin-right: 0px;
      .navbar-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px 10px;

        .con-vs-tooltip {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .feather-icon {
            transition: all 0.3s;
            .feather {
              width: 20px !important;
              height: 20px !important;
            }

            &:hover,
            &.active {
              transform: scale(1.2);
              color: rgba(var(--vs-primary), 1) !important;
            }
          }

          .material-icons,
          .material-icons-outlined {
            font-size: 24px;
            transition: all 0.3s;
            &:hover,
            &.active {
              transform: scale(1.2);
              color: rgba(var(--vs-primary), 1) !important;
            }
          }
        }
      }
    }
  }
  .moreCaseButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    &.active,
    // &:hover {
    //   color: rgba(var(--vs-primary), 1);
    // }

    .flag {
      margin-right: 5px;
      margin-bottom: 2px;
      border-radius: 50%;
      width: 16px;
      height: 16px;
    }
  }
  .test-group-table {
    margin: 30px 0px;
    margin-top: 0px;
    padding: 20px;
    background-color: #fafafa;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    .error-message {
      display: block;
      height: 19px;
      color: rgba(var(--vs-danger), 1);
      font-size: 0.65rem;
    }
    .feather {
      width: 16px !important;
      height: 16px !important;
    }
    h2 {
      font-size: 18px;
      margin-bottom: 30px;
    }
    .btn-actions {
      display: flex;
      justify-content: flex-end;
    }

    .vs-pagination--buttons,
    .item-pagination {
      width: 30px;
      height: 30px;
    }
    .item-pagination {
      font-size: 0.8rem;
    }

    .con-vs-pagination {
      padding: 7px;
    }
    .horizontal-nav {
      display: flex;
      float: right;
      margin-right: 0px;
      .navbar-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px 10px;

        .con-vs-tooltip {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .feather-icon {
            transition: all 0.3s;
            .feather {
              width: 20px !important;
              height: 20px !important;
            }

            &:hover,
            &.active {
              transform: scale(1.2);
              color: rgba(var(--vs-primary), 1) !important;
            }
          }

          .material-icons,
          .material-icons-outlined {
            font-size: 24px;
            transition: all 0.3s;
            &:hover,
            &.active {
              transform: scale(1.2);
              color: rgba(var(--vs-primary), 1) !important;
            }
          }
        }
      }
    }
  }
}
</style>